import React from "react";
import Head from "../components/Head";
import Footer from "../components/Footer";
import styled, { createGlobalStyle } from "styled-components";
import MenuContext from "../context/Menu";
import { Helmet } from "react-helmet";
import { useIntl } from "gatsby-plugin-intl";
import { MenuItem } from "../types/MenuItem";
import ScrollContext from "../context/Scroll";
import CookieBar from "../components/CookieBar";
import OgImage from "../images/slider/slide_3/bg.jpg";
import { SectionPros } from "../types/SectionsProps";

const GlobalStyle = createGlobalStyle`
  html{
    scroll-behavior: smooth;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
  }
  body {
    font-family: 'Lato';
    margin: 0;
    padding: 0;
    position: center;
  }
  @keyframes slide-in {
    100% { transform: translateX(0%); }
  }

  @-webkit-keyframes slide-in {
      100% { -webkit-transform: translateX(0%); }
  }

  @keyframes slide-out {
    
    100% { transform: translateX(-100%); }
}

  @-webkit-keyframes slide-out {
      
      100% { -webkit-transform: translateX(-100%); }
  }

`;

const Wrapper = styled.div`
  grid-template-areas: "Header" "Content" "Footer";
  grid-template-rows: auto 1fr auto;
  min-height: 100vh;
`;

const Content = styled.main`
  padding-top: 75px;
`;
type MainProps = {
  title?: string;
  showMenu?: boolean;
};

export const Main: React.FC<MainProps> = ({
  children,
  title,
  showMenu = true,
}) => {
  const intl = useIntl();
  const hash = typeof window !== "undefined" ? window.location.hash : null;
  const initialPosition =
    typeof window !== "undefined" ? window.scrollY + 80 : 0;
  const menuItems: MenuItem[] = [
    {
      key: "about-us",
      link: "/#about-us",
      isHome: true,
    },
    {
      key: "why-step",
      link: "/#why-step",
      isHome: false,
    },
    {
      key: "testimonials",
      link: "/#testimonials",
      isHome: false,
    },
    {
      key: "services",
      link: "/#services",
      isHome: false,
    },
    {
      key: "where-we-are",
      link: "/#where-we-are",
      isHome: false,
    },
  ];

  const [activeItem, setActiveItem] = React.useState(hash);
  const [scrolling, setScrolling] = React.useState(false);
  const [currentPosition, setCurrentPosition] = React.useState(initialPosition);

  const addScrollListener = () => {
    typeof window !== "undefined" &&
      window.addEventListener("scroll", scrollCallback, false);
  };

  const removeScrollListener = () => {
    window.removeEventListener("scroll", scrollCallback, false);
  };

  React.useEffect(() => {
    setActiveItem(hash);
  }, [hash]);

  React.useEffect(() => {
    const t = setTimeout(() => {
      setScrolling(false);
    }, 200);

    return () => {
      clearTimeout(t);
      setScrolling(true);
    };
  }, [currentPosition]);

  React.useEffect(() => {
    addScrollListener();
    return () => removeScrollListener();
  }, []);

  const scrollCallback = () => {
    setCurrentPosition(window.scrollY);
  };

  return (
    <>
      <Helmet>
        <html lang={intl.locale} />
        <title>STEP - Your business, one step beyond</title>
        <meta
          name="description"
          content={intl.formatMessage({
            id: "services.subTitle",
          })}
        />
        <meta property="og:title" content="STEP" />
        <meta
          property="og:description"
          content="Your business, one step beyond"
        />
        <meta property="og:image" content={OgImage} />
      </Helmet>
      <GlobalStyle />
      <Wrapper>
        <MenuContext.Provider value={{ activeItem, setActiveItem }}>
          <Head items={menuItems} showMenu={showMenu} />
          <Content>
            <ScrollContext.Provider
              value={{
                scrolling,
                setScrolling,
                currentPosition,
                setCurrentPosition,
              }}
            >
              {children}
            </ScrollContext.Provider>
          </Content>
          <Footer items={menuItems} title={title} />
        </MenuContext.Provider>
        <CookieBar />
      </Wrapper>
    </>
  );
};
