import { Link, useIntl } from "gatsby-plugin-intl";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Button, { ButtonType } from "../Button";
import Container from "../Container";

const CookieDiv = styled.div`
  position: fixed;
  height: 92px;
  width: 100%;
  bottom: 0;
  background-color: #ffffff;
  z-index: 999;
  display: flex;
  align-items: center;
  box-shadow: 0px -5px 10px rgba(6, 37, 54, 0.1);
  @media (max-width: 768px) {
    height: auto;
  }
`;

const CookieDisclaimer = styled.p`
  color: #999999;
  font-size: 14px;
  flex: 5;
  margin-right: 50px;
  @media (max-width: 768px) {
    margin-right: 0;
    font-size: 12px;
    flex: 1;
  }
`;

const ButtonWrapper = styled.div`
  flex: 1;
  width: 100%;
  @media (max-width: 768px) {
    margin-bottom: 20px;
  }
`;

const CookieLink = styled((props) => <Link {...props} />)`
  color: #46b4fb;
  text-decoration: none;
  font-weight: bold;
`;

const Content = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const CookieBar: React.FC = () => {
  const intl = useIntl();
  const [open, setOpen] = useState<string | undefined>(undefined);

  useEffect(() => {
    const initialState =
      (typeof localStorage !== undefined && localStorage.getItem("cookie")) ||
      "false";
    setOpen(initialState);
  }, []);

  const clickHandler = () => {
    setOpen("true");
    typeof localStorage !== undefined && localStorage.setItem("cookie", "true");
  };

  return (
    <React.Fragment>
      {open === "false" && (
        <CookieDiv>
          <Container>
            <Content>
              <CookieDisclaimer>
                {intl.formatMessage({ id: "cookie.disclaimer" })}
                <CookieLink to={"/cookie/"}>
                  {intl.formatMessage({ id: "cookie.link" })}
                </CookieLink>
              </CookieDisclaimer>
              <ButtonWrapper>
                <Button variant={ButtonType.default} onClick={clickHandler}>
                  {intl.formatMessage({ id: "cookie.button" })}
                </Button>
              </ButtonWrapper>
            </Content>
          </Container>
        </CookieDiv>
      )}
    </React.Fragment>
  );
};

export default CookieBar;
