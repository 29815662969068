import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  @media (max-width: 1024px) {
    max-width: 900px;
  }
`;

const Content = styled.div`
  max-width: 100%;
  @media (max-width: 1200px) {
    padding: 0 20px;
  }
`;

const Container: React.FC = ({ children }) => {
  return (
    <Wrapper>
      <Content>{children}</Content>
    </Wrapper>
  );
};

export default Container;
