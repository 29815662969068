import React from "react";
import styled from "styled-components";
import { CodeColors } from "../../types/CodeColors";

export enum ButtonType {
  primary = "#dc2870",
  default = "#ffffff",
}

enum ElementType {
  Button,
  Link,
}

type ButtonProps = {
  type?: ElementType;
  variant?: ButtonType;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  href?: string;
};

const ButtonWrapper = styled.button<{ variant: ButtonType }>`
  border-radius: 5px;
  padding: 15px 20px;
  background: ${(props) => props.variant};
  border: ${(props) =>
    props.variant === ButtonType.default
      ? `${CodeColors.darkBlue} solid 1px`
      : `${CodeColors.magenta} solid 1px`};
  color: ${(props) =>
    props.variant === ButtonType.default
      ? CodeColors.darkBlue
      : CodeColors.white};
  font-size: 20px;
  font-weight: normal;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  cursor: pointer;
  width: 100%;
`;

const LinkWrapper = styled.a<{ variant: ButtonType }>`
  border-radius: 5px;
  padding: 15px 20px;
  background: ${(props) => props.variant};
  border: ${(props) =>
    props.variant === ButtonType.default
      ? CodeColors.darkBlue + "solid 1px"
      : "none"};
  color: ${(props) =>
    props.variant === ButtonType.default
      ? CodeColors.darkBlue
      : CodeColors.white};
  font-size: 20px;
  font-weight: normal;
  text-transform: uppercase;
  text-decoration: none;
  display: flex;
  cursor: pointer;
  justify-content: center;
`;

const Button: React.FC<ButtonProps> = ({
  type = ElementType.Button,
  onClick,
  href,
  variant,
  children,
}) => {
  return type === ElementType.Button ? (
    <ButtonWrapper
      variant={variant ? variant : ButtonType.primary}
      onClick={onClick && onClick}
    >
      {children}
    </ButtonWrapper>
  ) : (
    <LinkWrapper href={href} variant={variant ? variant : ButtonType.primary}>
      {children}
    </LinkWrapper>
  );
};

export default Button;
