import React from "react";

interface DropDownProps {
  open: boolean;
  fill?: string;
}

export const DropDownIcon: React.FC<DropDownProps> = ({
  open,
  fill = "#C4C4C4",
}) => {
  const styleDown = { transform: "rotate(180deg)", transformOrigin: "50% 50%" };
  return (
    <svg
      width="40"
      height="10"
      viewBox="0 0 12 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path style={open ? styleDown : {}} d="M1 1l5 5 5-5" stroke={fill} />
    </svg>
  );
};
