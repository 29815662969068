import React from "react";

type IScrollContext = {
  currentPosition: number;
  scrolling: boolean;
  setScrolling: (scrolling: boolean) => void;
  setCurrentPosition: (position: number) => void;
};

const ScrollContext = React.createContext<IScrollContext>({
  scrolling: false,
  setScrolling: () => {
    return;
  },
  currentPosition: 0,
  setCurrentPosition: () => {
    return;
  },
});

export default ScrollContext;
export const useScroll = (): IScrollContext => React.useContext(ScrollContext);
