export enum CodeColors {
  cyan = "#46B4FB",
  darkBlue = "#16435F",
  magenta = "#DC2870",
  blue = "#00769F",
  lightBlue = "#58DEDE",
  white = "#FFFFFF",
  dark = "#062536",
  gray = "#CCCCCC",
  gray50 = "rgb(204, 204, 204)",
  dark70 = "rgb(6, 37, 54)",
  blue70 = "rgb(0, 118, 159)",
}
