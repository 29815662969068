import React from "react";
import styled from "styled-components";
import Container from "../Container";
import LogoFooter from "../../images/logo_white.svg";
import LogoFooterMobile from "../../images/logo_white_mobile.svg";
import { Paragraph } from "../Typography";
import { Link, useIntl } from "gatsby-plugin-intl";
import TwitterIcon from "../../images/social/twitter.svg";
import LinkedinIcon from "../../images/social/linkedin.svg";
import YoutubeIcon from "../../images/social/youtube.svg";
import { MenuItemsProps } from "../../types/MenuItemsProps";
import { CodeColors } from "../../types/CodeColors";
import { SectionPros } from "../../types/SectionsProps";

const FooterWrapper = styled.div`
  background: ${CodeColors.darkBlue};
  padding: 45px 0;
`;
const PostWrapper = styled.div`
  background: ${CodeColors.dark};
  text-align: center;
  padding: 20px 0;
`;

const Logo = styled.img`
  @media (max-width: 768px) {
    display: none;
  }
`;
const LogoMobile = styled.img`
  display: none;
  @media (max-width: 768px) {
    display: block;
    margin-bottom: 20px;
  }
`;
const NavWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex: 3;
  @media (max-width: 768px) {
    flex: 1;
  }
`;

const SocialContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const SocialIcon = styled.img`
  margin-left: 15px;
`;

const FooterContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

const Nav = styled.nav`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 20px 0;
  @media (max-width: 768px) {
    flex: 1;
    gap: 20px 10px;
    margin-bottom: 20px;
    padding: 0;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
  }
`;

const NavItem = styled((props) => <Link {...props} />)`
  padding: 0 20px;
  margin: 0;
  display: inline-block;
  text-decoration: none;
  font-size: 18px;
  line-height: 18px;
  font-weight: bold;
  color: ${CodeColors.white};
  text-transform: uppercase;
  margin: 0;
  text-align: left;
  @media (max-width: 768px) {
    &:nth-child(odd) {
      text-align: right;
    }
  }
  @media (max-width: 1024px) {
    font-size: 15px;
    padding: 0 10px;
  }
`;

const Footer: React.FC<MenuItemsProps & SectionPros> = ({ items, title }) => {
  const intl = useIntl();
  return (
    <footer>
      <FooterWrapper>
        <Container>
          <FooterContent>
            <Link to={"/"}>
              <Logo alt="STEP" src={LogoFooter} />
              <LogoMobile alt="STEP" src={LogoFooterMobile} />
            </Link>
            <Nav>
              {items.map((item) => (
                <NavItem key={item.key} to={item.link}>
                  {intl.formatMessage({ id: `menu.${item.key}` })}
                </NavItem>
              ))}
              <NavItem to={"/cookie/"}>
                {intl.formatMessage({ id: "cookie.cookieFooter" })}
              </NavItem>
            </Nav>
            <SocialContent>
              <a
                title="Twitter"
                target="_blank"
                rel="noreferrer"
                href="https://twitter.com/business_step"
              >
                <SocialIcon alt="Twitter" src={TwitterIcon} />
              </a>
              <a
                title="Linkedin"
                target="_blank"
                rel="noreferrer"
                href="https://www.linkedin.com/company/step-4-business"
              >
                <SocialIcon alt="Linkedin" src={LinkedinIcon} />
              </a>
              <a
                title="YouTube"
                target="_blank"
                rel="noreferrer"
                href="https://www.youtube.com/channel/UClqCD8sLP3Sw910tv2kujpQ"
              >
                <SocialIcon alt="YouTube" src={YoutubeIcon} />
              </a>
            </SocialContent>
          </FooterContent>
        </Container>
      </FooterWrapper>
      <PostWrapper>
        <Container>
          <Paragraph
            color={CodeColors.white}
            dangerouslySetInnerHTML={{ __html: title || "" }}
          />
        </Container>
      </PostWrapper>
    </footer>
  );
};

export default Footer;
