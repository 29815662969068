import React from "react";
import styled from "styled-components";
import logo from "../../images/logo.svg";
import Container from "../Container";
import { useMenu } from "../../context/Menu";
import LanguageSwitch from "../LanguageSwitch";
import { useIntl, Link } from "gatsby-plugin-intl";
import { MenuItemsProps } from "../../types/MenuItemsProps";
import { CodeColors } from "../../types/CodeColors";

type NavItemProps = {
  active?: boolean;
};

const Wrapper = styled.header`
  height: 75px;
  background: ${CodeColors.white};
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  display: flex;
  align-items: center;
  position: fixed;
  z-index: 999;
`;

const Content = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
`;

const Nav = styled.nav`
  overflow: auto;
  white-space: nowrap;
  align-items: center;
  display: flex;
`;

const NavItem = styled.span<NavItemProps>`
  display: inline-block;
  text-align: center;
  text-decoration: none;
  margin-left: 35px;
  font-size: 16px;
  line-height: 18px;
  font-weight: bold;
  color: ${(props) =>
    props.active ? CodeColors.magenta : CodeColors.darkBlue};
  transition: color 0.5s ease;
  text-transform: uppercase;
  @media (max-width: 1024px) {
    display: none;
  }
`;

const Logo = styled.img`
  height: 32px;
`;

const Header: React.FC<MenuItemsProps> = ({ items, showMenu = true }) => {
  const { activeItem, setActiveItem } = useMenu();

  const intl = useIntl();
  return (
    <Wrapper>
      <Container>
        <Content>
          <Nav>
            <Link to={"/"}>
              <Logo alt="STEP" src={logo} />
            </Link>
            {showMenu &&
              items.map((item) => (
                <Link to={item.link} key={item.key}>
                  <NavItem
                    active={
                      (!activeItem && item.isHome) ||
                      `/${activeItem}` === item.link
                    }
                    onClick={() => {
                      setActiveItem(`#${item.key}`);
                    }}
                  >
                    {intl.formatMessage({ id: `menu.${item.key}` })}
                  </NavItem>
                </Link>
              ))}
          </Nav>
          <LanguageSwitch />
        </Content>
      </Container>
    </Wrapper>
  );
};

export default Header;
