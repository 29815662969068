import React from "react";
import styled from "styled-components";
import { CodeColors } from "../../types/CodeColors";

type SubTitleProps = {
  color?: CodeColors;
  margin?: string;
};

export const Title = styled.h4`
  font-size: 42px;
  margin: 20px 0;
  color: ${(props) => (props.color ? props.color : CodeColors.darkBlue)};
  justify-content: center;
`;

export const SubTitle = styled.h5<
  React.HTMLAttributes<HTMLTitleElement> & SubTitleProps
>`
  font-size: 24px;
  line-height: 32px;
  color: ${(props) => (props.color ? props.color : CodeColors.darkBlue)};
  font-weight: 400;
  margin: 10px 0;
`;

export const Paragraph = styled.p<SubTitleProps>`
  font-size: 14px;
  line-height: 20px;
  color: ${(props) => (props.color ? props.color : CodeColors.darkBlue)};
  font-weight: normal;
  margin: 0;
  padding: 0;
  white-space: pre-line;
`;
