import { Link } from "gatsby";
import React, { useState } from "react";
import styled from "styled-components";
import { DropDownIcon } from "./DropDownIcon";
import { useIntl } from "gatsby-plugin-intl";
import { CodeColors } from "../../types/CodeColors";

const Wrapper = styled.div`
  position: relative;
  display: inline-block;
`;

const Content = styled.div`
  position: absolute;
  border: 1px solid ${CodeColors.gray};
  background: ${CodeColors.white};
  box-sizing: border-box;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  z-index: 100;
  margin-top: 12px;
  width: 60px;
`;

const ActiveLang = styled.span`
  color: ${CodeColors.cyan};
  text-transform: uppercase;
  font-weight: bold;
`;

const LangItem = styled.span`
  display: block;
  &:first-child {
    padding-top: 8px;
  }
  &:last-child {
    padding-bottom: 8px;
  }
  padding-top: 4px;
  padding-bottom: 4px;
  & > a {
    color: rgba(0, 118, 159, 1);
    text-decoration: none;
    display: block;
    font-weight: bold;
    text-transform: uppercase;
    text-align: center;
    &:active,
    &:hover {
      color: ${CodeColors.cyan};
    }
  }
`;

const DropDownButton = styled.button`
  margin: 0;
  padding: 0;
  border: none;
  background-color: ${CodeColors.white};
  &:focus {
    outline: none;
  }
  &:hover {
    cursor: pointer;
  }
`;

const languages = [
  {
    id: "it",
    link: "/",
    text: "ita",
  },
  {
    id: "en",
    link: "/en",
    text: "eng",
  },
  {
    id: "es",
    link: "/es",
    text: "esp",
  },
  {
    id: "pt",
    link: "/pt",
    text: "por",
  },
  {
    id: "de",
    link: "/de",
    text: "deu",
  },
];

const LanguageSwitch: React.FC = () => {
  const intl = useIntl();
  const hash = typeof window !== "undefined" ? window.location.hash : null;
  const [open, setOpen] = useState(false);

  return (
    <Wrapper>
      <ActiveLang>
        {languages.find((i) => i.id === intl.locale)?.text}
      </ActiveLang>
      <DropDownButton
        onClick={() => {
          setOpen(!open);
        }}
      >
        <DropDownIcon open={open} />
      </DropDownButton>
      {open && (
        <Content>
          {languages.map(
            (language) =>
              language.id !== intl.locale && (
                <LangItem key={language.text}>
                  <Link
                    to={`${language.link}${location.pathname
                      .replace(
                        `/${intl.locale}/`,
                        language.id !== intl.defaultLocale ? "/" : ""
                      )
                      .replace("/v1", "")}${hash}`}
                    replace={true}
                  >
                    {language.text}
                  </Link>
                </LangItem>
              )
          )}
        </Content>
      )}
    </Wrapper>
  );
};

export default LanguageSwitch;
