import React from "react";

type IMenuContext = {
  activeItem: string | null;
  setActiveItem: (i: string) => void;
};

const MenuContext = React.createContext<IMenuContext>({
  activeItem: null,
  setActiveItem: () => {
    return;
  },
});

export default MenuContext;
export const useMenu = (): IMenuContext => React.useContext(MenuContext);
